
/**
 * @name: 商品库存查询
 * @author: itmobai
 * @date: 2023-07-25 13:52
 * @description：商品库存查询
 * @update: 2023-07-25 13:52
 */
import { Vue, Component } from "vue-property-decorator"
import { ICrudOption } from "@/types/m-ui-crud";
import { mallGoodsStockExportApi, mallGoodsStockQueryByPageApi, mallGoodsStockDetailApi, mallGoodsStockInventoryApi } from "@/apis/site/productStock"
import { IProductStockParam } from "@/apis/site/productStock/types"
import { mallSiteQueryByListApi } from "@/apis/site/list"
import { deepCopy, exportFile } from "@/utils/common";

const validateActualStock = (rule: any, value: any, callback: any) => {
  if (value === '') {
    return callback(new Error('请输入实际库存'));
  }
  if (!/^[0-9]\d*$/.test(value)) {
    return callback(new Error('请输入整数的实际库存'));
  }
  if (Number(value) > 999999) {
    return callback(new Error('实际库存最大长度不能超过999999'));
  }
  callback()
};

@Component({})
export default class productStockPage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: any[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  queryForm: IProductStockParam = {
    page: 1,
    limit: 10,
    stockSort: 1
  }
  amount = 0
  // 表单数据
  modelForm: Partial<any> = {}
  // 配置信息
  crudOption: ICrudOption = {
    dialogWidth: '600px',
    editTitle: "库存盘点",
    labelWidth: '100px',
    // @ts-ignore
    editBtnTitle: "提 交",
    column: [
    {
        label: "商品",
        prop: "goodsKeywords",
        hide: true,
        search: true,
        editHide: true,
        placeholder: "输入商品ID/名称模糊搜索"
      },
      {
        label: "ID",
        prop: "goodsId",
        align: "left",
        width: 180,
        editHide: true
      },
      {
        label: "商品名称",
        prop: "goodsName",
        align: "center",
        span: 12,
        editSlot: true
      },
      {
        label: "商品属性",
        prop: "goodsTypes",
        align: "center",
        search: true,
        type: "select",
        editHide: true,
        dicData: [
          {
            label: "单品",
            value: 1
          },
          {
            label: "套餐",
            value: 2
          }
        ]
      },
      {
        label: "商品分类",
        prop: 'productSortId',
        hide: true,
        search: true,
        placeholder: "请选择商品分类",
        type: "select",
        dicUrl: '/api/admin/productSort/dropDown',
        dicFormatter: (res: any) => ({list: res.data, label: 'sortName', value: 'id'}),
      },
      {
        label: "商品分类",
        prop: 'sortName',
        align: "center",
      },
      {
        label: "站点",
        prop: "siteName",
        align: "center",
        overHidden: true,
        span: 12,
        editSlot: true
      },
      {
        label: "站点",
        prop: "siteId",
        search: true,
        type: "select",
        dicData: [],
        hide: true,
        editHide: true
      },
      {
        label: "实际库存",
        prop: "checkBehindQty",
        hide: true,
        type: "number",
        rules: [
          {required: true, message: "请输入实际库存", trigger: "blur"},
          {validator: validateActualStock, trigger: "blur"},
        ]
      },
      {
        label: "系统记录库存",
        prop: "stockQty",
        hide: true,
        editSlot: true
      },
      {
        label: "盘点结果",
        prop: "pdResult",
        hide: true,
        editSlot: true
      },
      {
        label: "盘点说明",
        prop: "checkDescription",
        hide: true,
        type: "textarea",
        maxlength: 200,
        showWordLimit: true,
        span: 24
      },
      {
        label: "相关图片",
        prop: "checkPic",
        hide: true,
        span: 24,
        type: "image",
        editSlot: true
      },
      {
        label: "商品库存",
        prop: "stockQty",
        align: "center",
        width: 100,
        editHide: true,
        // @ts-ignore
        sortable: "custom"
      },
      {
        label: "状态",
        prop: "goodsStatus",
        editHide: true,
        search: true,
        type: "select",
        align: "center",
        width: 100,
        dicData: [
          {
            label: "上架",
            value: 1
          },
          {
            label: "下架",
            value: 2
          }
        ]
      }
    ]
  }

  /**
   * 获取盘点
   */
  public get getInventoryResult () {
    if (!this.modelForm.checkBehindQty && !this.modelForm.stockQty) {
      return `--`
    }
    if (Number(this.modelForm.checkBehindQty) === Number(this.modelForm.stockQty)) {
      return `盘平`
    }
    if (Number(this.modelForm.checkBehindQty) > Number(this.modelForm.stockQty)) {
      return `盘赢${Number(this.modelForm.checkBehindQty || 0) - Number(this.modelForm.stockQty || 0)}份`
    }
    return `盘亏${Number(this.modelForm.stockQty || 0) - Number(this.modelForm.checkBehindQty || 0)}份`
  }

  getList () {
    this.tableLoading = true
    mallGoodsStockQueryByPageApi(this.queryForm).then(e => {
      this.amount = e.amount;
      this.tableTotal = e.goodsStockVoPage.total
      this.tableData = e.goodsStockVoPage.records
      this.tableLoading = false
    })
  }

  openEdit (row: any, index: number) {
    mallGoodsStockDetailApi(row.id).then(e => {
      let obj: any = deepCopy(e);
      obj.checkBehindQty = ''
      // @ts-ignore
      this.$refs.crudRef.rowEdit(obj, index)
    })
  }

  getSiteSelect () {
    mallSiteQueryByListApi().then(e => {
      // @ts-ignore
      this.$refs.crudRef.updateSearchColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
      // @ts-ignore
      this.$refs.crudRef.updateFormColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
    })
  }

  rowEdit (form: any, done: Function, loading: Function) {
    const obj: any = deepCopy(form)
    delete obj.id
    delete obj.goodsName
    delete obj.goodsStatus
    delete obj.goodsTypes
    delete obj.saleQty
    delete obj.siteName
    obj.checkFrontQty = obj.stockQty
    delete obj.stockQty
    mallGoodsStockInventoryApi(obj).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  beforeUpload (file: File) {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === "image/jpg";
    const isLt2M = file.size / 1024 / 1024 < 1;

    if (!isJPG) {
      this.$message.error('上传图片只能是 JPG、GIF、JPEG、PNG 格式!');
    }
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 1MB!');
    }
    return isJPG && isLt2M;
  }

  /**
   * 导出excel
   */
  exportExcel () {
    mallGoodsStockExportApi(this.queryForm).then(e => {
      exportFile(e, '商品库存.xlsx')
    })
  }

  sortChange(e: any) {
    this.queryForm.page = 1
    if (e.order === "ascending") {
      // 升序
      this.queryForm.stockSort = 1
    } else {
      // 降序
      this.queryForm.stockSort = 2
    }
    this.getList()
  }

  created () {
    this.getList()
    this.getSiteSelect()
  }
}
