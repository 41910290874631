/**
 * @name: 商品库存接口
 * @author: itmobai
 * @date: 2023-07-25 13:57
 * @description：商品库存接口
 * @update: 2023-07-25 13:57
 */
import { get, postJ } from "@/request"
import { IProductStockParam } from "./types"

export const mallGoodsStockQueryByPageApi = (query: IProductStockParam) => get("/mall/goodsStock/queryByPage", query)

export const mallGoodsStockExportApi = (query: IProductStockParam) => get("/mall/goodsStock/export", query, "blob")

export const mallGoodsStockDetailApi = (id: string) => get("/mall/goodsStock/detail/" + id)

export const mallGoodsStockInventoryApi = (data: any) => postJ("/mall/goodsStock/inventory", data)
